import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [0,2,3,4,5];

export const dictionary = {
		"/": [~6],
		"/api": [7],
		"/api/auth/logout": [~8],
		"/api/auth/steam": [~9],
		"/clans": [~10],
		"/clans/[clan_id]": [~11],
		"/esports": [~12],
		"/esports/player/[player_slug]": [13,[2]],
		"/esports/tournaments": [~14],
		"/esports/tournaments/[tournament_slug]": [~15,[3]],
		"/faq": [16],
		"/god/[god]": [~17],
		"/insights": [~18],
		"/leaderboard": [~19],
		"/leaderboard/[leaderboard_id]": [~20],
		"/lobbies": [~21,[4]],
		"/lobbies/customs": [~22,[4]],
		"/lobbies/open": [~23,[4]],
		"/login": [~24],
		"/logout": [~25],
		"/match/[match_id]": [~26],
		"/privacy": [27],
		"/profile/[profile_id]": [~28,[5]],
		"/replays": [~29],
		"/settings": [~30],
		"/terms": [31],
		"/tools": [32]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';